/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it


exports.onClientEntry = () => {
    if (window.Prism) {
        document.removeEventListener('DOMContentLoaded', window.Prism.highlightAll)
    }
}
